// Import all env vars from .env file
require('dotenv').config();

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";

// Basic Authentication - Staging Tool
import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";

// Material UI
import "assets/scss/material-kit-react.scss?v=1.10.0";

// Page Components
import LandingPage from "views/LandingPage/LandingPage.js";
import AboutPage from "views/AboutPage/AboutPage.js";
import TeamPage from "views/TeamPage/TeamPage.js";
import TermsPage from "views/TermsPage/TermsPage.js";
import PrivacyPage from "views/PrivacyPage/PrivacyPage.js";
import ContactPage from "views/ContactPage/ContactPage.js";
import CareersPage from "views/CareersPage/CareersPage.js";
import ThanksPage from "views/ThanksPage/ThanksPage.js";

// 404 Page Component
import NotFoundPage from "views/NotFoundPage/NotFoundPage.js";

// User Authentication Page Components
import LoginPage from "views/LoginPage/LoginPage.js";
import RegisterPage from "views/RegisterPage/RegisterPage.js";
import ResetPage from "views/ResetPage/ResetPage.js";
import DashboardPage from "views/DashboardPage/DashboardPage.js";

var hist = createBrowserHistory();

function App() {
  return (
    <div className="app">
      <Protect
        sha512={process.env.REACT_APP_PASSWORD}
        boxTitle="This app is password protected"
        inputPlaceholder="Enter Password"
        buttonLabel="Send"
      >
        <Router history={hist}>
          <Routes>
            <Route path="/what-we-do" element={<AboutPage />} />
            <Route path="/our-team" element={<TeamPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/thanks" element={<ThanksPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/reset" element={<ResetPage />} />
            <Route exact path="/dashboard" element={<DashboardPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </Protect>
    </div>
  );
}

export default App;
