import React, { useState } from "react";
import { Router, Routes, Route, Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import ThanksPage from "views/ThanksPage/ThanksPage.js";

import styles from "assets/jss/material-kit-react/views/formPage.js";

import image from "assets/img/background.jpeg";

const config = require("data/siteConfig");

const useStyles = makeStyles(styles);

// FIREBASE SDDK
import apiApp from '../../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const sendEmail = httpsCallable(functions, 'sendEmail');

const initialState = {
  contactName: '',
  contactEmail: '',
  messageSubject: '',
  messageBody: '',
  buttonText: 'Send Message',
  color: 'primary',
  h0neypot: '',
};

// HONEYPOT SWITCH
const displayH0neypot = true;

export default function ContactPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const [formValues, setFormValues] = useState(initialState);

  // Pre-Submit for validations and disabling button
  const [processing, setProcessing] = useState(false);

  const preSubmit = async (e) => {


    if (formValues.h0neypot.length !== 0) {
      console.log('H0neypot triggered. Operation cancelled')
      return false;

    // Ensure all fields are non-empty - basic validation
    } else if ( formValues.contactName.length > 0
      && formValues.contactEmail.length > 0
      && formValues.messageSubject.length > 0
      && formValues.messageBody.length > 0
    ) {

      ////////////////
      // VALIDATION //
      ////////////////

      var isValid = true;

      // Validate email inputs first
      if (typeof formValues.contactEmail !== "undefined") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(formValues.contactEmail)) {
          isValid = false;
          console.log('Validation Error - EMAIL');
          updateButton('Enter a correctly formatted email address', 'danger');
        }
      }

      // If passed all Validaty checkpoints - send call
      if (isValid) {

        e.preventDefault();
        console.log('Sending...');
        updateButton('Sending...', 'info');
        setProcessing(true);

        console.log('formValues: ', formValues);
        console.log('formValues.contactName: ', formValues.contactName);
        console.log('formValues.contactEmail: ', formValues.contactEmail);
        console.log('formValues.messageSubject: ', formValues.messageSubject);
        console.log('formValues.messageBody: ', formValues.messageBody);
        console.log('formValues.h0neypot: ', formValues.h0neypot);

        // Firebase SDK callable function - sendMail
        sendEmail({
          contactName: formValues.contactName,
          contactEmail: formValues.contactEmail,
          messageSubject: formValues.messageSubject,
          messageBody: formValues.messageBody,
          h0neypot: formValues.h0neypot
        })
          // RESULT IF SUCCESS
          .then((result) => {
            const data = result.data;
            const sanitizedMessage = data.message;

            console.log('Message Sent');
            updateButton('Message Sent', 'success');
            setProcessing(false);

            // Wait 2 seconds to allow feedback
            setTimeout(() => {console.log('Timeout Expired')}, 2000);

            postSubmit();

          })
          // ERROR HANDLING
          // .catch((error) => {
          //   const code = error.code;
          //   const message = error.message;
          //   const details = error.details;
          //   console.log('Message NOT Sent - Error: ' + details);
          //   updateButton('Message NOT Sent', 'danger');
          //
          // });

      }
    } else {

      // BASIC VALIDATION
      console.log('Invalid!');
      updateButton('Please Complete the Form', 'warning');
      return false
    }
  };

  // Post-Submit for navigating to 'Thank You' page
  const postSubmit = () => {
    window.open("/thanks", "_self");
  }

  // Simple controlled form setup
  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

    console.log(
      'contactName: ' + formValues.contactName + '\r\n' +
      'contactEmail: ' + formValues.contactEmail + '\r\n' +
      'messageSubject: ' + formValues.messagSubject + '\r\n' +
      'messageBody: ' + formValues.messageBody + '\r\n' +
      'buttonText: ' + formValues.buttonText + '\r\n' +
      'honeypot: ' + formValues.h0neypot + '\r\n'
    );
  }

  const updateButton = (message, color) => {
    setFormValues(previousState => {
      return {
        ...previousState,
        buttonText: message,
        color: color
      }
    });
  }

  const resetForm = () => {
    setFormValues(initialState);
  }

  const classes = useStyles();
  const { ...rest } = props;
  return (

    <div>
      <Header
        absolute
        color="transparent"
        brand="X200 LABS"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Contact Us</h4>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href={config.twitterUrl}
                        target="_blank"
                        color="transparent"
                        onClick={() => {
                          window.open(config.twitterUrl, "_blank");
                        }}
                      >
                        <i className={"fab fa-twitter"} />
                      </Button>
                      <Button
                        justIcon
                        href={config.facebookUrl}
                        target="_blank"
                        color="transparent"
                        onClick={() => {
                          window.open(config.facebookUrl, "_blank");
                        }}
                      >
                        <i className={"fab fa-facebook"} />
                      </Button>
                      <Button
                        justIcon
                        href={config.instagramUrl}
                        target="_blank"
                        color="transparent"
                        onClick={() => {
                          window.open(config.instagramUrl, "_blank");
                        }}
                      >
                        <i className={"fab fa-google"} />
                      </Button>
                    </div>
                  </CardHeader>
                  <p className={classes.divider}>Want to hire us?</p>
                  <CardBody>
                    <CustomInput
                      labelText="Your Name..."
                      color={classes.primary}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        id: "contactName",
                        name: "contactName",
                        onChange: handleChange,
                        required: true,
                      }}
                      value={formValues.contactName}
                    />
                    <CustomInput
                      labelText="Your Email..."
                      color={classes.primary}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        id: "contactEmail",
                        name: "contactEmail",
                        onChange: handleChange,
                        required: true,
                      }}
                      value={formValues.contactEmail}
                    />
                    <CustomInput
                      labelText="Your Subject..."
                      color={classes.primary}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        id: "messageSubject",
                        name: "messageSubject",
                        onChange: handleChange,
                        required: true,
                      }}
                      value={formValues.messageSubject}
                    />
                    <CustomInput
                      labelText="Your Message..."
                      color={classes.primary}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea,
                      }}
                      inputProps={{
                        type: "textarea",
                        id: "messageBody",
                        name: "messageBody",
                        multiline: true,
                        rows: 5,
                        onChange: handleChange,
                        required: true,
                      }}
                      value={formValues.messageBody}
                    />
                    {displayH0neypot &&
                      <div
                        className="h0neypot-wrapper"
                        style={{
                          display: 'none'
                        }}
                      >
                        <CustomInput
                          labelText="If you are a human, do not fill this out. You catch more b0ts with h0ney."
                          formControlProps={{
                            fullWidth: true,
                            className: classes.h0neypot,
                          }}
                          inputProps={{
                            type: "text",
                            id: "h0neypot",
                            name: "h0neypot",
                            onChange: handleChange,
                            required: false,
                          }}
                          value={formValues.h0neypot}
                        />
                      </div>
                    }
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      color={formValues.color}
                      size="lg"
                      disabled={processing}
                      onClick={(e) => {
                        preSubmit(e)
                      }}
                    >
                      { formValues.buttonText }
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
      <Routes>
        <Route path="/contact-us/thanks" component={ThanksPage} />
      </Routes>
    </div>
  );
}
