import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import logo from "assets/img/logo-512.png";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <img src={logo} alt="..."
            className={imageClasses}
            width={"150px"}
            height={"150px"}
            style={{backgroundColor: "black", borderRadius: "50%"}}
          />
          <h2 className={classes.title}>Cutting-Edge Consulting</h2>
          <h5 className={classes.description}>
            X200 Labs is an Austin based firm founded in 2013 to help companies acheive their project goals by providing expertise in remote sensing, wireless communications, automated product testing, hardware design, software architecture, UI/UX, and system administration in various industries.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
