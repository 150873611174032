import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// Auth components
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import styles from "assets/jss/material-kit-react/views/formPage.js";

import image from "assets/img/background.jpeg";

const config = require("data/siteConfig");

const useStyles = makeStyles(styles);

const initialState = {
  userEmail: '',
  userPw: '',
  buttonText: 'Login',
  color: 'primary',
  h0neypot: '',
};

// HONEYPOT SWITCH
const displayH0neypot = true;

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const [formValues, setFormValues] = useState(initialState);

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);

  // Pre-Submit for validations and disabling button
  const [processing, setProcessing] = useState(false);

  const preSubmit = async (e) => {


    if (formValues.h0neypot.length !== 0) {
      console.log('H0neypot triggered. Operation cancelled')
      return false;

    // Ensure all fields are non-empty - basic validation
  } else if ( formValues.userEmail.length > 0
      && formValues.userPw.length > 0
    ) {

      ////////////////
      // VALIDATION //
      ////////////////

      var isValid = true;

      // Validate email inputs first
      if (typeof formValues.userEmail !== "undefined") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(formValues.userEmail)) {
          isValid = false;
          console.log('Validation Error - EMAIL');
          updateButton('Enter a correctly formatted email address', 'danger');
        }
      }

      // If passed all Validaty checkpoints - send call
      if (isValid) {

        e.preventDefault();
        console.log('Sending...');
        updateButton('Sending...', 'info');
        setProcessing(true);

        console.log('formValues: ', formValues);
        console.log('formValues.userEmail: ', formValues.userEmail);
        console.log('formValues.userPw: ', formValues.userPw);
        console.log('formValues.h0neypot: ', formValues.h0neypot);

        // CALL FUNCTION - DO WORK
        logInWithEmailAndPassword(formValues.userEmail, formValues.userPw)

      }
    } else {

      // BASIC VALIDATION
      console.log('Invalid!');
      updateButton('Please Complete the Form', 'warning');
      return false
    }
  };

  // Simple controlled form setup
  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

    console.log(
      'userEmail: ' + formValues.userEmail + '\r\n' +
      'userPw: ' + formValues.userPw + '\r\n' +
      'buttonText: ' + formValues.buttonText + '\r\n' +
      'honeypot: ' + formValues.h0neypot + '\r\n'
    );
  }

  const updateButton = (message, color) => {
    setFormValues(previousState => {
      return {
        ...previousState,
        buttonText: message,
        color: color
      }
    });
  }

  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand={config.siteName}
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login</h4>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#"
                        target="_self"
                        color="transparent"
                        onClick={signInWithGoogle}
                      >
                        <i className={"fab fa-google"} />
                      </Button>
                    </div>
                  </CardHeader>

                  <p className={classes.divider}>
                    <Link to="/reset">Reset Password</Link><br/>
                    Don't have an account? <Link to="/register">Register now</Link>
                  </p>

                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      color={classes.primary}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        id: "userEmail",
                        name: "userEmail",
                        onChange: handleChange,
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      value={formValues.userEmail}
                    />
                    <CustomInput
                      labelText="Password"
                      color={classes.primary}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        id: "userPw",
                        name: "userPw",
                        onChange: handleChange,
                        required: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                      value={formValues.userPw}
                    />
                    {displayH0neypot &&
                      <div
                        className="h0neypot-wrapper"
                        style={{
                          display: 'none'
                        }}
                      >
                        <CustomInput
                          labelText="If you are a human, do not fill this out. You catch more b0ts with h0ney."
                          formControlProps={{
                            fullWidth: true,
                            className: classes.h0neypot,
                          }}
                          inputProps={{
                            type: "text",
                            id: "h0neypot",
                            name: "h0neypot",
                            onChange: handleChange,
                            required: false,
                          }}
                          value={formValues.h0neypot}
                        />
                      </div>
                    }
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      color={formValues.color}
                      size="lg"
                      disabled={processing}
                      onClick={(e) => {
                        preSubmit(e);
                      }}
                    >
                      { formValues.buttonText }
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
