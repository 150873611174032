import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import mark from "assets/img/team/mark_headshot.png";
import matt from "assets/img/team/matt_headshot.png";
import david from "assets/img/team/david_headshot.png";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Our Team</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={mark} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Mark Sapp
                <br />
                <small className={classes.smallTitle}>Principal Engineer</small>
              </h4>
              <CardBody>
                <Button
                  justIcon
                  href="https://www.linkedin.com/in/msapp/"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                  onClick={() => {
                    window.open("https://www.linkedin.com/in/msapp/", "_blank");
                  }}
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
                <p className={classes.description}>
                  With over 15 years of experience designing and programming embedded devices, Mark has developed products for many industries, including robotics, mobile and, transportation.
                  <br /><br />
                  Mark has lead teams at fortune 500 companies and worked on flagship consumer products. After receiving a Master's and Bachelor's of Science in Electrical Engineering from Texas Tech University, Mark joined the R&D team at National Instruments working on their real-time OS and hardware-in-the-loop technology.
                  <br /><br />
                  Mark later joined the teams at Aeroflex and Graftek before founding x200 Labs.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>

              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={david} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                David Sapp
                <br />
                <small className={classes.smallTitle}>Software Engineer</small>
              </h4>
              <CardBody>
                <Button
                  justIcon
                  href="https://www.linkedin.com/in/avidsapp/"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                  onClick={() => {
                    window.open("https://www.linkedin.com/in/avidsapp/", "_blank");
                  }}
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
                <p className={classes.description}>
                  David has over 10 years of experience in designing, developing, and deploying software architecture, platforms, and applications. David has lead stealth software start-up teams and delivered full-stack apps in the web, mobile, automation, devops, and IoT industries.
                  <br /><br />
                  Prior to joining x200 labs in February 2021, David founded and managed a bespoke full-stack software development firm for over 7 years.
                  <br /><br />
                  David holds a Master's in Business Administration and a Master's in Information Systems with an emphasis in Technology Innovation from University of Colorado Denver as well as a Bachelor's of Science in Industrial Distribution from Texas A&M University.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>

              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={matt} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Matt Sapp
                <br />
                <small className={classes.smallTitle}>Principal Engineer</small>
              </h4>
              <CardBody>
                <Button
                  justIcon
                  href="https://www.linkedin.com/in/matthewsapp/"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                  onClick={() => {
                    window.open("https://www.linkedin.com/in/matthewsapp/", "_blank");
                  }}
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
                <p className={classes.description}>
                  Prior to joining x200 labs in January 2020, Matt spent 6 years in the semiconductor industry working to help Texas Instruments maximize product line efficiency by developing data capture and analytic tools throughout the stages of production.
                  <br /><br />
                  Matt then joined Raytheon Missile Systems for several more years as a Systems Engineer to help tackle complex problems in signal and image processing.
                  <br /><br />
                  Matt holds a Master's and Bachelor's of Science in Electrical Engineering from Texas Tech University with an emphasis in Microwave RADAR Systems and Imaging.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>

              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
