import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import styles from "assets/jss/material-kit-react/views/landingPageSections/carouselStyle.js";

import ni from 'assets/img/partners/ni.png';
import apple from 'assets/img/partners/apple.png';
import tesla from 'assets/img/partners/tesla.png';
import boeing from 'assets/img/partners/boeing.png';
import iota from 'assets/img/partners/iota.svg';
import mopeka from 'assets/img/partners/mopeka.png';
import qorvo from 'assets/img/partners/qorvo.png';
import qualcomm from 'assets/img/partners/qualcomm.png';
import viavi from 'assets/img/partners/viavi.png';
import graftek from 'assets/img/partners/graftek.png';
import hoverstat from 'assets/img/partners/hoverstat.png';
import smartypans from 'assets/img/partners/smartypans.png';

const IMAGES = [
  {
    company: 'ni',
    src: ni,
    url: 'https://ni.com',
  },
  {
    company: 'apple',
    src: apple,
    url: 'https://apple.com',
  },
  {
    company: 'tesla',
    src: tesla,
    url: 'https://tesla.com',
  },
  {
    company: 'boeing',
    src: boeing,
    url: 'https://boeing.com',
  },
  {
    company: 'iota',
    src: iota,
    url: 'https://iotamotion.com',
  },
  {
    company: 'mopeka',
    src: mopeka,
    url: 'https://mopeka.com',
  },
  {
    company: 'qorvo',
    src: qorvo,
    url: 'https://qorvo.com',
  },
  {
    company: 'qualcomm',
    src: qualcomm,
    url: 'https://qualcomm.com',
  },
  {
    company: 'viavi',
    src: viavi,
    url: 'https://viavi.com',
  },
  {
    company: 'graftek',
    src: graftek,
    url: 'https://graftek.com',
  },
  {
    company: 'hoverstat',
    src: hoverstat,
    url: 'https://hoverstat.com',
  },
  {
    company: 'smartypans',
    src: smartypans,
    url: 'https://smartypans.com',
  }
];

const useStyles = makeStyles(styles);

export default function PartnerCarouselSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgFluid
  );

  const settings = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const renderSlides = () =>
    IMAGES.map(item => (
      <div>
        <a href={item.url}>
          <img src={item.src}
            className={imageClasses}
            style={{
              width: "5em",
              height: "5em",
              objectFit: "contain",
            }}
          />
        </a>
      </div>
    ));

  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Collaborated with...</h2>
      <Slider {...settings}>
        {renderSlides()}
      </Slider>
    </div>
  );
}
